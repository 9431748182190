import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import App from './App'
import { createGlobalStyle } from 'styled-components'

export const BasicGlobalStyle = createGlobalStyle`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  input, select, textarea, button {
    font-family: ${({ theme }) => theme.font.family.regular};
  }
  
  html,body{
    font-family: ${({ theme }) => theme.font.family.regular};
    margin: 0;
    line-height: 1.5;
  }

  body {
    background-color: ${(props) => props.theme.color.background};
    color: ${(props) => props.theme.color.text}
  }
`
const theme = {
  color: {
    unset: 'unset',
    transparent: 'transparent', // used to remove color from elements that have a default value set

    success: '#72bb53',
    error: '#e61610',

    background: '#fdfdfd',
    accentText: '#ffffff',
    accent: '#1360BB',
    accentHover: '#0F4A90',
    text: '#202020',
    textMuted: '#969696',
    label: '#202020',
    input: '#202020',
    inputBorder: '#8b8b8b',
    shadow: '#5f5f5f82',
    buttonGreyed: '#c4c4c4',
    buttonGreyedHover: '#9b9999',

    black: '#0a0909',
    white: '#ffffff',
    outline: '#13020f',
  },
  font: {
    size: {
      normal: '1rem',
      small: '0.875rem',
      extraSmall: '0.75rem',
      placeholder: '0.813rem',
      label: '1rem',
    },
    family: {
      regular: '"Source Sans Pro", Roboto, sans-serif; font-weight: 400;',
      numberInput: 'Cousine, "Source Sans Pro", Roboto, mono; font-weight: 400;',
      menuHeader: '"Source Sans Pro", Roboto, sans-serif; font-weight: 700;',
      label: '"Source Sans Pro", Roboto, sans-serif; font-weight: 600;',
      medium: '"Source Sans Pro", Roboto, sans-serif; font-weight: 600;',
    },
  },
  breakpoints: {
    desktop: '24rem',
  },
  borderRadius: {
    container: '1rem',
    input: '4px',
    button: '4px',
  },
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('app')
)

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { BasicGlobalStyle } from '.'

const Iframe = styled.iframe`
  width: 640px;
  height: 530px;
  border: none;
`

const themeTockedMoken = {
  background: '#252525',
  accent: '#13bb29',
  accentText: '#1f1f1f',
  accentHover: '#0a8b1b',
  text: '#e0e0e0',
  textMuted: '#969696',
  label: '#e0e0e0',
  input: '#e0e0e0',
  inputBorder: '#8b8b8b',
  shadow: '#d3d3d382',
  buttonGreyed: '#c4c4c4',
  buttonGreyedHover: '#9b9999',
}
const App = () => {
  const [data, setData] = useState({ done: false })
  const [myTheme, setMyTheme] = useState('mockedToken')
  const url = `https://cleanpay.mokajava.com/${myTheme}`

  const switchTheme = () => {
    setMyTheme((t) => (t === 'mockedToken' ? 'tockedMoken' : 'mockedToken'))
  }

  useEffect(() => {
    const handler = (event) => {
      if (event.origin !== 'https://cleanpay.mokajava.com') return
      setData({
        ...event.data.message,
      })
    }
    window.addEventListener('message', handler)
    // clean up
    return () => window.removeEventListener('message', handler)
  }, [])

  return (
    <ThemeProvider
      theme={(theme) =>
        myTheme === 'tockedMoken' ? { ...theme, color: { ...theme.color, ...themeTockedMoken } } : { ...theme }
      }
    >
      <BasicGlobalStyle />

      <div css='display: grid; grid-template-columns: 1fr auto; gap: 1.5rem; margin: 3rem;'>
        <div
          css={`
            padding: 2rem;
            border: none;
            border-radius: 5px;
            background-color: ${(props) => props.theme.color.background};
            box-shadow: 1px 1px 4px 1px ${(props) => props.theme.color.shadow};

            h2 {
              color: ${(props) => props.theme.color.accent};
            }
          `}
        >
          <h2 css='margin-top: 0;'>Test Info</h2>
          <pre>
            <div>SUCCESS: 4242424242424242 734|934|843</div>
            <div>SUCCESS: 5555555555554444 734|934|843</div>
            <div>SUCCESS: 2223003122003222 734|934|843</div>
            <div>SUCCESS: 6011111111111117 734|934|843</div>

            <div>ERROR: 4000000000009979 !734|!934|!843</div>
            <div>ERROR: 4100000000000019 !734|!934|!843</div>
            <div>ERROR: 378282246310005 any cvv</div>
          </pre>
          <h2>Theme</h2>
          <button onClick={switchTheme}>Switch Theme</button>
          <h2>Status Callback</h2>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
        <div
          css={`
            padding: 1rem;
            border: none;
            border-radius: 5px;
            background-color: ${(props) => props.theme.color.background};
            box-shadow: 1px 1px 4px 1px ${(props) => props.theme.color.shadow};
          `}
        >
          {url && (
            <Iframe
              custVeri={data.status === 'cust_verification_required'}
              // src='http://localhost:1234/tockedMoken?maxWidth=700px'
              src={url}
              sandbox='allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App
